<template>
  <div class="Main">
    <el-menu
      :default-active="navLeftActive"
      class="el-menu-demo TopMenu"
      background-color="rgb(0 0 0 / 0%)"
      :active-text-color="'#0343dd'"
      mode="horizontal"
      active-background-color="#fff"
      text-color="black"
      :router="true"
      :collapse-transition="false"
      @select="handleSelect"
    >
      <template v-for="(item, index) in routerList">
        <el-submenu
          :index="item.path"
          :key="index"
          v-if="item.children.length != 1"
          class="submenu"
        >
          <template slot="title">
            <i class="marginRight svgI">
              <svg-icon :data_iconName="item.meta.icon" :className="item.meta.icon+'_icon'" class="svgIcon"
            /></i>
            <span slot="title">{{ $i18n.locale=='zh'?item.meta.title1:item.meta.title }}</span>
          </template>
          <div  v-for="(itm, inx) in item.children" :key="inx">
                <el-menu-item
                  :index="itm.path"
                  v-if="!itm.children"
                  ><template slot="title">
                  <!-- <i>
                <svg-icon :data_iconName="itm.meta.icon" :className="itm.meta.icon+'_icon'" class="svgIcon"
              /></i> -->
                  <!-- <a :href="itm.meta.href" v-if="itm.meta.href"><span slot="title">{{ itm.meta.title }}</span></a> -->
                  <span slot="title" >{{ $i18n.locale=='zh'?itm.meta.title1:itm.meta.title }}</span>
  
                </template>
                </el-menu-item>
                <el-submenu
                  :index="itm.path"
                  :key="inx"
                  class="submenu"
                  v-else
                   style="background:#eee;"
                >
                <template slot="title">
                <!-- <i>
                  <svg-icon :data_iconName="itm.meta.icon" :className="itm.meta.icon+'_icon'" class="svgIcon"
                /></i> -->
                    <span slot="title">{{ $i18n.locale=='zh'?itm.meta.title1:itm.meta.title }}</span>
                </template>
                <el-menu-item
                  :index="it.path"
                  v-for="(it,ix) in itm.children"
                  :key="ix"
                  ><template slot="title">
                  <!-- <i>
                <svg-icon :data_iconName="it.meta.icon" :className="it.meta.icon+'_icon'" class="svgIcon"
              /></i> -->
                  <!-- <a :href="itm.meta.href" v-if="itm.meta.href"><span slot="title">{{ itm.meta.title }}</span></a> -->
                  <span slot="title">{{ $i18n.locale=='zh'?it.meta.title1:it.meta.title }}</span>
  
                </template>
                </el-menu-item>
                </el-submenu>
              </div>
        </el-submenu>
        <el-menu-item
          :index="item.children[0].path"
          v-if="item.children.length == 1"
          :key="index"
        >
            <i class="marginRight svgI" :style="{color:color}">
              <svg-icon :data_iconName="item.meta.icon" :className="item.meta.icon+'_icon'" class="svgIcon"
            /></i>
            <span slot="title">{{ $i18n.locale=='zh'?item.meta.title1:item.meta.title }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import {jbrowse} from "@/api/dataList"
export default {
  name: "MenuTab",
  props: {
    color:{
      type:String,
      default:"#000"
    }
  },
  data() {
    return {
      routerList: [],
      options:[{
        value:'Jbrowse',
        label:'Jbrowse',
       children:[]
      }]
    };
  },
  components: {
    //引入模块
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      if(this.$route.path==key){
        // location.reload()
        this.$router.go(0)
      }
    },
    getMenuLinks: function () {
      /* jbrowse().then((res)=>{
        if(res.data.code==1){
          this.options.children=[]
          for(var i=0;i<res.data.data.length;i++){
            
            this.options[0].children.push({label:res.data.data[i].label,value:res.data.data[i].url})
          }
        }
      }) */
      var t = this.$router.options.routes.filter(function (val, index, arr) {
        // console.log(val, index, arr)
        /* if(val.name=='toolsView'){
          // this.options.children.push({label:val.children})
        } */
        if(val.name!="detail"&&val.name!="function"){
          return val.children;
        }
        
      });
      
      this.routerList = t;
      // console.log(t,"252525")
      //t returns a vue object instance
      // return t._children ;
      // did not know how to iterate this
    },
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    navLeftActive: function () {
      console.log(this.$route);
      const { meta, path } = this.$route;
      console.log(path, 111);
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path; //"/" + path.split("/")[1];
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    this.getMenuLinks();
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style scoped>
/* .Main >>> .el-menu-item.is-active {
  background-color: #0c6279 !important;
} */
.Main >>> .el-menu{
  margin-left: -95px
}
/* @media screen and (max-width: 1440px) {
.Main >>> .el-menu{
  margin-left: -130px
}
} */
.Main >>> .el-menu-item{
  padding: 0 32px !important;
  /* height: 70px;
  line-height: 70px; */
}
@media screen and (max-width: 1440px) {
.Main >>> .el-menu-item { padding: 0 20px !important;
}
}
.el-menu--horizontal>.el-menu-item{
  /* line-height: 60px !important; */
  
}
.el-menu--horizontal>>>.el-submenu__title{
  /* line-height: 60px !important; */
}
.Main >>> .el-menu-item:hover {
  background: rgb(0 0 0 / 0%) !important;
  /* color: #fff !important; */
}

.Main >>> .el-submenu__title:hover {
  background: rgb(0 0 0 / 0%) !important;
  /* color: #fff !important; */
}
.Main >>> .el-menu-item.is-active {
  background: rgb(0 0 0 / 0%) !important;
  /* border-bottom-color:#285495 !important; */
  border: 0;
  /* color: #fff !important; */
}
.Main >>> .el-submenu__title.is-active {
  background: rgb(0 0 0 / 0%) !important;
  /* color: #fff !important; */
}
.TopMenu {
border-bottom:0 !important;
/* margin-left:100px !important; */
}
/* .TopMenu {
  border: none;
  text-align: left;
}
.TopMenu >>> .el-submenu__title i {
  color: #000;
}
.TopMenu:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
} */
/* .el-submenu{
  margin-top: 5px;
} */
.svgIcon{
  width: 18px !important;
  height: 25px !important;
}
.svgI{
  font-size: 20px;
  margin-right: 5px !important;
  color: currentColor !important;
}
.el-menu--horizontal .el-menu-item{
  background: #eee  !important;
}
.el-menu--horizontal .el-menu-item:hover{
  background: #1d4255 !important;
  color: white !important;
  border-bottom: 2px dashed white;
}
</style>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>