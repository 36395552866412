module.exports = {
    language: {
      name: '英文'
    },
    home: {
      title:'介绍',
      text:'极智基因数据库是一套集成了存储、管理、查询和分析功能的数据库系统，可基于基因组学数据，实现各类型数据库的一键化搭建，包括单基因组数据库、泛基因组数据库、多组学数据库以及种质资源数据库等。',
      text1:'极智基因数据库旨在优化原始数据管理模式，从而提高大数据背景下用户对海量基因组学数据的管理与分析能力。标准版数据库包含Home、 Omics、 Variations、 Analysis、 Tools、 Download、 Contact七大模块，分别提供主页总结、可视化浏览、基因组统计信息、变异信息查找、共线性分析、分析工具、结果下载等功能，帮助用户实现对组学数据的储存、管理和使用等。在此基础上，还可根据各类型组学数据特点和用户需求，定制开发个性化功能模块，实现数据库的定制化搭建。',
      Summary:'总结',
      Features:'特色功能',
      Superiority:'优势',
      ContactUs:'联系我们',
      ContactUs1:'感谢您访问极智基因基因组数据库！我们可以根据您的需求，提供数据库个性化定制方案。',
      ContactUs2:'联系我们，为您量身订制数据库。',
      phone:'电话',
      Email:'邮箱',
      Website:'网址',
      Address:'地址',
      Address1:'天津市武清商务区国际企业社区A9座2层',
    },
    page:{
      Sumbit:'查询',
      Reset:'重置',
      Search:'查询',
      Download:'下载',
      yes:'是',
      no:'否',
      placeholder:'请输入查询内容',
      Pathway:'信号传导途径',
      Introduction:'介绍',
      RepeatMasker:'重复序列',
      RepeatProteinMask:'重复蛋白序列',
      SampleList:'样品列表',
      Correlation:'相关性',
      Pvalue:'P值',
      Genelist:'基因列表',
      ControlGroup:'对照组',
      ExperimentGroup:'实验组',
      padj:'padj值',
      log2FoldChange:'log2FoldChange值',
      Genome:'基因组',
      inputContent:'查询内容',
      Database:'数据库',
      BlastType:'类型',
      Input:'输入DNA或蛋白质序列',
      Expect:'期望',
      Maxtargets:'最大目标数',
      Type:'类型',
      GeneAnnotation:'基因注释',
      GeneStructure:'基因结构',
      SequenceInformation:'序列信息',
      Querytype:'查询类型',
      display:'显示带碱基的基因型？',
      Selectedsamples:'实验组选择',
      Queryinput:'查询内容',
      Examples:'示例',
      Species:'物种',
      seqResult:'序列提取结果',
      click:'点击下载结果文件',
      Threshold:'阈值-Log10（p值）',
      Trait:'特性',
      Genome:'基因组',
      GeneID:'基因ID',
      Upstream:'上游',
      Downstream:'下游',
      Variationtype:'变异类型',
      SourceGenome:'来源基因组',
      TargetGenome:'目标基因组',
      GeneList:'基因列表',
      SampleList:'样品列表'
    },
    title:{
        Result:'结果',
        tableResult:'表格结果',
        TransposableElements:'转位因子',
        SNPSeek:'恒久性变异',
        SequenceExtraction:'序列提取',
        VariationsSearch:'变异查询',
        Syntenic:'关联性查询',
        OrthologousGene:'同源基因',
        GeneCoExpression:'基因共表达',
        Deseq2:'差异基因分析',
        KEGG:'KEGG富集',
        GO:'GO富集',
        Transcriptomics:'转录组学',
        Epigenomics:'表观基因组学',
        PhylogeneticAnalysis:'进化树',
        WGCNA:'权重基因共表达网络分析',
        GWAS:'变异查询'
    }
  }