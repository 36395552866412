<template>
  <div id="app">

    <router-view/>
  </div>
</template>
<script>
import {jbrowse} from "@/api/dataList"
import router from './router'
export default {
  name: "home",
  props: {
    //传值
  },
  data() {
    return {
    };
  },
  components: {
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    

//以下是调用上面的函数

var mb = this.myBrowser();

if ("IE" == mb) {

alert("我是 IE");
this.$alert('请使用其他浏览器', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            window.open("../changeBr.html", "_top").close()
          }
        });
}

if ("FF" == mb) {

// alert("我是 Firefox");

}

if ("Chrome" == mb) {

// alert("我是 Chrome");
  /* this.$alert('请使用其他浏览器', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            window.open("../changeBr.html", "_top").close()
          }
        }); */
}

if ("Opera" == mb) {

// alert("我是 Opera");

}

if ("Safari" == mb) {

// alert("我是 Safari");
  this.$alert('请使用其他浏览器', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            window.open("../changeBr.html", "_top").close()
          }
        });
}
/* window.addEventListener('beforeunload',()=>{
            localStorage.removeItem('Admin-Token');
            this.$router.push('/login')
    }); */
    // this.getPage()
  },
  methods: {
    // 组件的方法
    getPage(){
// var timing=setInterval(function(){
  if(localStorage.getItem('Admin-Token')){
    jbrowse().then((res)=>{
      console.log(res,"jbrowse")
      if(res.data.code==1){
        /* this.jbrowseList = res.data.species.map((item) => {
          return { label: item.value, value: item.label };
        }); */
        
    // clearInterval(timing)
        var label=[]
        var url=[]
        for(var key in res.data.data){
          label.push({
              path: '/Jbrowse/'+res.data.data[key].label,
              component: () => import('@/views/pages/tools/Jbrowse'),
              name: res.data.data[key].label,
              meta: { title: res.data.data[key].label, url:res.data.data[key].url,title1: res.data.data[key].label,icon:'genome'}
          })
        }
        router.options.routes[6].children[4].children=label
        // console.log(router.options.routes,111)
        router.addRoutes(router.options.routes);
      }
    })
  }
    },
     myBrowser(){
       var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串

    var isOpera = userAgent.indexOf("Opera") > -1;
    if (isOpera) {

return "Opera"

}; //判断是否Opera浏览器

if (userAgent.indexOf("Firefox") > -1) {

return "FF";

} //判断是否Firefox浏览器

if (userAgent.indexOf("Chrome") > -1){

return "Chrome";

}

if (userAgent.indexOf("Safari") > -1) {

return "Safari";

} //判断是否Safari浏览器

if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {

return "IE";

}; //判断是否IE浏览器


     }

  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: "Microsoft YaHei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
}

</style>
<style>
body{
  margin: 0;
}
.main{
  width: 80%;
  margin: 10px auto;
    background: white;
    padding: 20px;
    min-height: calc(100vh - 260px);
}
.pageTop{
  display: flex;
    justify-content: space-between;
}
/* .box{
    border-radius: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
} */
.title{
  height: 50px;
    background: #0c6279db;
    color: white;
    /* border-radius: 5px 5px 0 0; */
    border-radius: 10px;
    padding-left: 10px;
    line-height: 50px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bold;
}
.pagination{
  text-align: center;
}
.titleBox {
      font-size: 18px;/* no */
    color: black;
    margin: 10px 0;
    display: inline-block;
}
.collapse .el-collapse-item__header{
  height: 50px;
    background: #0c6279db;
    color: white;
    /* border-radius: 5px 5px 0 0; */
    border-radius: 10px;
    padding-left: 10px;
    line-height: 50px;
    margin-bottom: 10px;
    font-weight: bold;
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
  background: rgba(123, 194, 235, 1);
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
  border-radius: 3px;
  background: #ededed;
}
</style>
