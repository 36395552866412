module.exports = {
    language: {
      name: 'Chinese'
    },
    home: {
      title:'Introduction',
      text:'The Jizhi Gene Database is a database system that integrates storage, management, query, and analysis functions. Based on genomic data, it can realize the one-click construction of various types of databases, including single genomic database, pangenomic database, multigenomic database, and species resource database.',
      text1:'Jizhi Gene Database aims to optimize the original data management mode, thereby improving the management and analysis capabilities of users on massive genomic data in the context of big data. The standard version database includes seven modules: Home, Omics, Variations, Analysis, Tools, Download, and Contact. It provides functions such as homepage summary, visual browsing, genome statistics, mutation information search, collinearity analysis, analysis tools, and result download, respectively, to help users achieve the storage, management, and use of genomic data. On this basis, personalized functional modules can also be customized and developed according to the characteristics of various types of organizational data and user needs to achieve customized construction of the database.',
      Summary:'Summary',
      Features:'Features',
      Superiority:'Superiority',
      ContactUs:'Contact Us',
      ContactUs1:'Thank you for visiting the Jizhi Gene Genome Database! We can provide personalized database customization solutions based on your needs.',
      ContactUs2:'Contact us to customize the database for you.',
      phone:'Telephone',
      Email:'Email',
      Website:'Website',
      Address:'Address',
      Address1:'Floor 2, Building A9, International Enterprise Community, Wuqing Business District, Tianjin',
    },
    page:{
      Sumbit:'Sumbit',
      Reset:'Reset',
      Search:'Search',
      Download:'Download',
      yes:'yes',
      no:'no',
      placeholder:'Please enter the content',
      Pathway:'Pathway',
      Introduction:'Introduction',
      RepeatMasker:'RepeatMasker',
      RepeatProteinMask:'RepeatProteinMask',
      SampleList:'SampleList',
      Correlation:'Correlation',
      Pvalue:'Pvalue',
      Genelist:'Genelist',
      ControlGroup:'Control Group',
      ExperimentGroup:'Experiment Group',
      padj:'padj',
      log2FoldChange:'log2FoldChange',
      Genome:'Genome',
      inputContent:'inputContent',
      Database:'Database',
      BlastType:'Blast Type',
      Input:'Input DNA or Protein Sequence',
      Expect:'Expect',
      Maxtargets:'Max targets',
      Type:'Type',
      GeneAnnotation:'Gene Annotation',
      GeneStructure:'Gene Structure',
      SequenceInformation:'Sequence Information',
      Querytype:'Query type',
      display:'Display the genotype with bases?',
      Selectedsamples:'Selected samples',
      Queryinput:'Query input',
      Examples:'Examples',
      Species:'Species',
      seqResult:'Sequence extraction results',
      click:'Click to download results',
      Threshold:'Threshold -Log10(p value)',
      Trait:'Trait',
      Genome:'Genome',
      GeneID:'Gene ID',
      Upstream:'Upstream',
      Downstream:'Downstream',
      Variationtype:'Variationtype',
      SourceGenome:'Source Genome',
      TargetGenome:'Target Genome',
      GeneList:'Gene List',
      SampleList:'Sample List'
    },
    title:{
        Result:'Result',
        tableResult:'Table Result',
        TransposableElements:'Transposable Elements',
        SNPSeek:'SNP-Seek',
        SequenceExtraction:'Sequence Extraction',
        VariationsSearch:'Variations Search',
        Syntenic:'Syntenic',
        OrthologousGene:'Orthologous Gene',
        GeneCoExpression:'Gene Co-Expression',
        Deseq2:'Deseq2',
        KEGG:'KEGG Enrichment',
        GO:'GO Enrichment',
        Transcriptomics:'Transcriptomics',
        Epigenomics:'Epigenomics',
        PhylogeneticAnalysis:'Phylogenetic Analysis',
        WGCNA:'WGCNA',
        GWAS:'GWAS'
    }
  }